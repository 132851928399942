import { isImpact } from './dcContext'

export const getRlpnMaxLength = () => {
  return isImpact ? 23 : 20
}

export const replaceGoodsHolderText = (message: string) => {
  const regex = /goodsholder|rlpn\/rasn|rlpn|lpn/i
  return isImpact ? message.replace(regex, 'Tracking ID') : message
}
