export interface GradeConfig {
  key: string
  display: string
  qualityCode: string
  needsReason: boolean
}

export const GradeConfigs: Record<string, GradeConfig> = {
  A_GRADE: { key: 'A_GRADE', display: 'A-Grade', qualityCode: '01', needsReason: false },
  HASH: { key: 'HASH', display: 'Hash', qualityCode: '01', needsReason: false },
  B_GRADE: { key: 'B_GRADE', display: 'B-Grade', qualityCode: '02', needsReason: true },
  C_GRADE: { key: 'C_GRADE', display: 'C-Grade', qualityCode: '03', needsReason: true }
}

export const CGradeReason: Record<string, string> = {
  DIRTY: '020',
  DAMAGED: '016',
  WET: '050',
  'DAMAGED BY SECURITY TAG': '010',
  'SECURITY TAG (PRESENCE)': '011',
  'DIGITAL - POOR QUALITY': '021',
  DISCOLOURED: '030',
  'WRONG CONTENT': '060',
  'AMERICAN SKU': '081' // Only difference between this and SEC
}

export const BGradeReason: Record<string, string> = {
  'GENTLY WORN': 'G00',
  'COSMETICALLY FLAWED': 'C00',
  'LIKE NEW': 'L00'
}

export const CGradeReasonSEC: Record<string, string> = {
  DIRTY: '020',
  DAMAGED: '016',
  WET: '050',
  'DAMAGED BY SECURITY TAG': '010',
  'SECURITY TAG (PRESENCE)': '011',
  'DIGITAL - POOR QUALITY': '021',
  DISCOLOURED: '030',
  'WRONG CONTENT': '060'
}

// B grades for SEC all should have 000: https://jira.nike.com/browse/ELCTRINITY-815
export const BGradeReasonSEC: Record<string, string> = {
  'GENTLY WORN': '000',
  'COSMETICALLY FLAWED': '000',
  'LIKE NEW': '000'
}
