import { Button } from '@nike/eds'
import { dispatch } from 'api/redux'
import { showSnackbar } from 'components/snackbar'
import { type RASN } from 'types'
import { replaceGoodsHolderText } from 'utils/goodsholderContext'
import { type ApiHandlerContext } from 'utils/useApiHandler'

interface RasnDetailsPanelProps {
  goodsHolder: string | undefined
  rasnData: RASN
  processedQuantity: number
  finishRasnContext: () => void
  handlePauseCarton: () => void
  closeGoodsHolderContext: ApiHandlerContext<string>
}

export const RasnRlpnDetailsPanel = ({ goodsHolder, rasnData, processedQuantity, finishRasnContext, handlePauseCarton, closeGoodsHolderContext }: RasnDetailsPanelProps) => {
  const closeCarton = () => {
    if (goodsHolder && !closeGoodsHolderContext.isLoading) {
      closeGoodsHolderContext.handleApiCall(goodsHolder)
        .then()
        .catch(err => {
          if (err.status !== 404) throw err // this case should never be reached, if so it's a bug so throw
          dispatch(showSnackbar(replaceGoodsHolderText(`Unknown rlpn ${goodsHolder}`), 'error'))
        })
    }
  }

  return <div className='flex flex-col flex-grow justify-between'>
    <div className="grid grid-cols-[4fr,6fr] gap-x-2 gap-y-6 h-fit text-3xl">
      <div className="font-bold">RASN</div><div>{rasnData.number}</div>
      {goodsHolder && <>
        <div className="font-bold">{replaceGoodsHolderText('RLPN')}</div><div>{goodsHolder}</div>
      </>
      }
      <div className="font-bold">Customer</div><div>{rasnData.customerName}</div>
      <div className={'font-bold'}>Customer type</div><div>{rasnData.channel}</div>
      <div className="font-bold">Units handled</div><div>{processedQuantity}</div>
    </div>
    {goodsHolder
      ? (
        <div className={'flex'}>
          <Button className="mx-auto text-2xl"
            onClick={closeCarton}
          >
            Close carton
          </Button>
          <Button className="mx-auto text-2xl"
            onClick={handlePauseCarton}
            variant={'secondary'}
          >
            Pause carton
          </Button>
        </div>
        )
      : (
        <Button id={'finishRASNButton'} className="mx-auto text-2xl"
          onClick={finishRasnContext}
        >
          Finish RASN
        </Button>
        )}
  </div>
}
