import { trace, type Span } from '@opentelemetry/api'
import {
  useCloseGoodsHolderMutation,
  useOpenGoodsHolderMutation,
  useLazyGetGoodsHolderProcessedQuantityQuery as useGetProcessedQuantityGH
} from 'api/goodsholder'
import { useLazyFetchInstructionQuery } from 'api/instruction'
import { useFetchMaterialImageQuery } from 'api/material'
import { useCreateRasnMutation, useLazyGetRasnProcessedQuantityQuery as useGetProcessedQuantityRasn } from 'api/rasn'
import { dispatch } from 'api/redux'
import {
  useCreateUnitMutation,
  useUpdateUnitMutation,
  usePrintUnitLabelMutation,
  useCreateUnitBySkuMutation
} from 'api/unit'
import { hideSnackbar, showSnackbar } from 'components/snackbar'
import { useState, useEffect } from 'react'
import {
  type GoodsHolderData,
  LabelType,
  type RASN,
  type Unit,
  type PrintLabelResponse,
  GradeConfigs,
  type CreateUnitBySkuRequest
} from 'types'
import { Screen } from 'types'
import { type GradingInstruction } from 'types/GradingInstruction'
import { useApiHandler } from 'utils/useApiHandler'

const defaultUnit: Unit = {
  unitId: '',
  countryOfOrigin: '',
  countryOfOriginFull: '',
  styleDescription: '',
  productCode: '',
  sizeCode: '',
  upc: '',
  division: '',
  silhouette: '',
  grade: '',
  gradeReason: '',
  hashable: false,
  quantity: 0,
  qualityCode: '',
  isegCode: '',
  toteBarcode: '',
  status: '',
  imageUrl: '',
  lpn: ''
}

export const useUnitHandlingApi = () => {
  const [displayFailure, setDisplayFailure] = useState(false)
  const [unitData, setUnitData] = useState<Unit>(defaultUnit)
  const [rasnData, setRasnData] = useState<RASN>()
  const [goodsHolder, setGoodsHolder] = useState<string>()
  const [activeScreen, setActiveScreen] = useState<Screen>(Screen.SCAN_RLPN_RASN)
  const [processedQuantity, setProcessedQuantity] = useState<number>(0)
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false)

  const [openGoodsHolderMutation] = useOpenGoodsHolderMutation()
  const [closeGoodsHolderMutation] = useCloseGoodsHolderMutation()
  const [createRasnMutation] = useCreateRasnMutation()
  const [createUnitMutation] = useCreateUnitMutation()
  const [createUnitBySkuMutation] = useCreateUnitBySkuMutation()
  const [updateUnitMutation] = useUpdateUnitMutation()
  const [reprintLabel] = usePrintUnitLabelMutation()

  const [triggerGetGoodsHolderProcessedQuantity, { data: processedQuantityGoodsholder }] = useGetProcessedQuantityGH()
  const [triggerGetRasnProcessedQuantity, { data: processedQuantityRasn }] = useGetProcessedQuantityRasn()
  const [triggerGetInstructionData, { data: gradeInstructionData, isLoading: isInstructionsLoading, isError: isInstructionsError }] = useLazyFetchInstructionQuery()

  const tracer = trace.getTracer('rasn-processing')
  const [currentRasnSpan, setCurrentRasnSpan] = useState<Span | null>(null)
  const [currentUnitSpan, setCurrentUnitSpan] = useState<Span | null>(null)
  const [currentScreenSpan, setCurrentScreenSpan] = useState<Span | null>(null)
  const [gradeInstruction, setGradeInstruction] = useState<GradingInstruction>()

  const { data: fetchedImage, isError: isImageError, isLoading: isImageLoading, refetch: refetchImage } = useFetchMaterialImageQuery(unitData?.productCode, { skip: !unitData.productCode })

  useEffect(() => {
    if (unitData.productCode === '') return
    refetchImage()
  }, [unitData.productCode, refetchImage])

  useEffect(() => {
    if (!isImageLoading && fetchedImage && !isImageError) {
      setUnitData((prevState) => ({ ...prevState, imageUrl: fetchedImage.image }))
    } else if (!isImageLoading && isImageError) {
      setUnitData((prevState) => ({ ...prevState, imageUrl: process.env.PUBLIC_URL! + '/images/image-unavailable.jpg' }))
    }
  }, [isImageLoading, fetchedImage, isImageError])

  useEffect(() => {
    if (!isInstructionsLoading && !isInstructionsError) {
      setGradeInstruction(gradeInstructionData)
    } else if (!isInstructionsLoading && isInstructionsError) {
      setGradeInstruction(undefined)
      setShowWarningModal(true)
    }
  }, [isInstructionsLoading, gradeInstructionData, isInstructionsError])

  const overlayFailure = () => {
    setDisplayFailure(true)
    setTimeout(() => { setDisplayFailure(false) }, 999)
  }

  useEffect(() => {
    if (currentScreenSpan) {
      currentScreenSpan.end()
    }
    const newSpan = tracer.startSpan(`screen-${Screen[activeScreen]}`)
    setCurrentScreenSpan(newSpan)
    // Cleanup function when component unmounts
    return () => {
      newSpan.end()
    }
  }, [activeScreen])

  useEffect(() => {
    if (activeScreen === Screen.SCAN_UNIT) {
      if (goodsHolder) {
        triggerGetGoodsHolderProcessedQuantity(goodsHolder)
      } else if (rasnData) {
        triggerGetRasnProcessedQuantity(rasnData.number)
      }
    }
  }, [activeScreen, goodsHolder, rasnData, triggerGetGoodsHolderProcessedQuantity, triggerGetRasnProcessedQuantity])

  useEffect(() => {
    if (processedQuantityRasn && rasnData) {
      setProcessedQuantity(processedQuantityRasn.totalHandledUnits)
    }
  }, [processedQuantityRasn, rasnData])

  useEffect(() => {
    if (processedQuantityGoodsholder && goodsHolder) {
      setProcessedQuantity(processedQuantityGoodsholder.processedQuantity)
    }
  }, [processedQuantityGoodsholder, goodsHolder])

  const openRlpnContext = useApiHandler({
    apiFunction: async (rlpn: string) => await openGoodsHolderMutation(rlpn).unwrap(),
    successAction: (result: GoodsHolderData) => {
      setGoodsHolder(result.lpn)
      setRasnData(result.rasn)
      setActiveScreen(Screen.SCAN_UNIT)
    },
    failureAction: (error?: any) => {
      overlayFailure()
      if (error.status === 404 || error.status === 423) {
        throw error
      }
    }
  })

  const createRasnContext = useApiHandler({
    apiFunction: async (rasnId: string) => await createRasnMutation(rasnId).unwrap(),
    successAction: (result: RASN) => {
      setRasnData(result)
      setActiveScreen(Screen.SCAN_UNIT)
      const newSpan: Span = tracer.startSpan(`rasn-${result.number}`)
      newSpan.setAttribute('rasn', result.number)
      setCurrentRasnSpan(newSpan)
    },
    failureAction: (error?: any) => {
      overlayFailure()
      if (error.status === 404 || error.status === 423) {
        throw error
      }
    }
  })

  const createUnitSuccessAction = (result: Unit) => {
    triggerGetInstructionData(result.productCode)
    dispatch(hideSnackbar())
    setUnitData(result)
    setActiveScreen(Screen.GRADING)
    const newSpan: Span = tracer.startSpan(`unit-${result.unitId}`)
    newSpan.setAttribute('upc', result.upc)
    setCurrentUnitSpan(newSpan)
    currentRasnSpan?.setAttribute('unitsProcessed', result.productCode)
  }

  const createUnitFailureAction = (error?: any) => {
    overlayFailure()
    if (error.status === 404 || error.status === 423) {
      throw error
    }
  }

  const createUnitContext = useApiHandler({
    apiFunction: async (upc: string) => await createUnitMutation(
      { upc, lpn: goodsHolder ?? '' }
    ).unwrap(),
    successAction: createUnitSuccessAction,
    failureAction: createUnitFailureAction
  })

  const createUnitBySkuContext = useApiHandler({
    apiFunction: async (requestWithLpn: CreateUnitBySkuRequest) => await createUnitBySkuMutation(
      { ...requestWithLpn, lpn: goodsHolder ?? null }
    ).unwrap(),
    successAction: createUnitSuccessAction,
    failureAction: createUnitFailureAction
  })

  const updateUnitGradeContext = useApiHandler({
    apiFunction: async (unit: Unit) => await updateUnitMutation(unit).unwrap(),
    successAction: (unit: Unit) => {
      setUnitData((prevState) => ({ ...unit, imageUrl: prevState.imageUrl }))
      dispatch(showSnackbar(`Successfully ${getSimpleGrade(unit.grade)}-graded`, 'success', 999000))
      switch (unit.grade) {
        case GradeConfigs.A_GRADE.key:
        case GradeConfigs.B_GRADE.key:
        case GradeConfigs.HASH.key:
          setActiveScreen(Screen.REWORK)
          break
        case GradeConfigs.C_GRADE.key:
          dispatch(showSnackbar('Printing C-grade label...', 'info'))
          printLabelContext.handleApiCall(LabelType.CGrade)
          setActiveScreen(Screen.CGRADE_COMPLETION)
          break
        default:
          overlayFailure()
          break
      }
    },
    failureAction: overlayFailure
  })

  const printLabelContext = useApiHandler({
    apiFunction: async (labelType: LabelType) => await reprintLabel({ unitId: unitData.unitId, labelType }).unwrap(),
    successAction: (response: PrintLabelResponse) => {
      const message = response.status === 'OK' ? 'Successfully printed label' : 'Failed to print label'
      const status = response.status === 'OK' ? 'success' : 'error'
      dispatch(showSnackbar(message, status))
    },
    failureAction: () => {
      dispatch(showSnackbar('Failed to print label', 'error'))
    }
  })

  const resetUnitHandlingFlow = () => {
    setGradeInstruction(undefined)
    localStorage.removeItem('unitId')
    setUnitData(defaultUnit)
    currentUnitSpan?.end()
    setActiveScreen(Screen.SCAN_UNIT)
  }

  const updateUnitToteScanContext = useApiHandler({
    apiFunction: async (unit: Unit) => await updateUnitMutation(unit).unwrap(),
    successAction: (unit: Unit) => {
      dispatch(showSnackbar('Successfully scanned into tote', 'success', 999000))
      resetUnitHandlingFlow()
    },
    failureAction: overlayFailure
  })

  const confirmCgradeComplete = useApiHandler({
    apiFunction: async (unit: Unit) => await updateUnitMutation(unit).unwrap(),
    successAction: (unit: Unit) => {
      dispatch(showSnackbar('Successfully C-graded', 'success'))
      resetUnitHandlingFlow()
    },
    failureAction: overlayFailure
  })

  const closeGoodsHolderContext = useApiHandler({
    apiFunction: async (lpnNumber: string) => await closeGoodsHolderMutation(lpnNumber).unwrap(),
    successAction: () => {
      resetToScanRlpnRasn()
    },
    failureAction: overlayFailure
  })

  const resetToScanRlpnRasn = () => {
    setActiveScreen(Screen.SCAN_RLPN_RASN)
    setGoodsHolder(undefined)
    setRasnData(undefined)
    setUnitData(defaultUnit)
    currentRasnSpan?.end()
    currentUnitSpan?.end()
    currentScreenSpan?.end()
  }

  return {
    unitData,
    rasnData,
    setRasnData,
    goodsHolder,
    setGoodsHolder,
    activeScreen,
    setActiveScreen,
    processedQuantity,
    openRlpnContext,
    createRasnContext,
    createUnitContext,
    createUnitBySkuContext,
    updateUnitGradeContext,
    updateUnitToteScanContext,
    printLabelContext,
    confirmCgradeComplete,
    closeGoodsHolderContext,
    resetUnitHandlingFlow,
    resetToScanRlpnRasn,
    displayFailure,
    gradeInstruction,
    isInstructionsLoading,
    showWarningModal,
    setShowWarningModal
  }
}

const getSimpleGrade = (grade: string) => {
  switch (grade) {
    case 'A_GRADE':
      return 'A'
    case 'B_GRADE':
      return 'B'
    case 'C_GRADE':
      return 'C'
    default:
      return grade
  }
}
