import { type MaterialImage, type MaterialImageDTO, type MaterialWeightsAndMeasures } from 'types'

import { mainSplitApi } from './redux/mainSplitApi'

const toMaterialImage = (materialImageDto: MaterialImageDTO): MaterialImage => {
  return { image: materialImageDto.downloadUrl }
}

export const materialApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchMaterialImage: builder.query<MaterialImage, string>({
      query: (productCode) => {
        return {
          url: `/materials/${productCode}/image`,
          method: 'GET'
        }
      },
      transformResponse: (materialImageDto: MaterialImageDTO) => {
        return toMaterialImage(materialImageDto)
      }
    }),
    getWeightsAndMeasuresMissing: builder.query<MaterialWeightsAndMeasures, string>({
      query: (upc) => {
        return {
          url: '/materials',
          method: 'GET',
          params: { upc }
        }
      }
    })
  }),
  overrideExisting: false
})

export const { useFetchMaterialImageQuery, useGetWeightsAndMeasuresMissingQuery } = materialApi
