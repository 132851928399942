import { Button, Modal } from '@nike/eds'
import { type Dispatch, type SetStateAction } from 'react'

interface WarningModalProps {
  showWarningModal: boolean
  setDisplayModal: Dispatch<SetStateAction<boolean>>
}

export const WarningModal = ({ showWarningModal, setDisplayModal }: WarningModalProps) => {
  const closeModal = () => {
    setDisplayModal(false)
  }

  const headerSlot = (
    <div>
      <p className='text-5xl p-2'>Please contact your Help chain</p>
    </div>
  )

  const footerSlot = (
    <div className='flex justify-end'>
      <Button className='text-xl' onClick={closeModal}>Continue</Button>
    </div>
  )

  return (
    <>
      <Modal
        isOpen={showWarningModal}
        onDismiss={closeModal}
        headerSlot={headerSlot}
        footerSlot={footerSlot}
        hideFade
      >
        <p className='text-3xl'>Could not load the special instructions</p>
      </Modal>
    </>
  )
}
