import { Text } from '@nike/eds'
import { TrinityButton } from 'components/trinity-button'
import { GradeConfigs } from 'types'
import { type GradingInstruction } from 'types/GradingInstruction'

interface MainGradesProps {
  handleGradeChange: (newGrade: string) => void
  activeGrade: string
  hashable: boolean
  aGradable: boolean
  bGradable: boolean
  cGradable: boolean
  instruction: GradingInstruction | undefined
}

export const MainGradeButtons = ({ handleGradeChange, activeGrade, hashable, aGradable, bGradable, cGradable, instruction }: MainGradesProps) => {
  const disabledMessage = instruction ? 'is disabled for this SKU' : 'is not enabled'

  return <>
    <Text font={'display-2'} as={'h1'} className={'mb-6'}>
      Grade
    </Text>

    <div className={'button-grid'}>
      {hashable
        ? <TrinityButton className={`grid-button full-left ${hashable ? 'background-blue' : 'background-grey'}`}
          active={activeGrade === GradeConfigs.HASH.key}
          disabled={!hashable}
          disabledMessage={`Hash ${disabledMessage}`}
          size={'massive'}
          onClick={() => { handleGradeChange(GradeConfigs.HASH.key) }}>
          Hash
        </TrinityButton>
        : <TrinityButton className={`grid-button full-left ${aGradable ? 'background-green' : 'background-grey'}`}
          active={activeGrade === GradeConfigs.A_GRADE.key}
          disabled={!aGradable}
          disabledMessage={`A-grade ${disabledMessage}`}
          size={'massive'}
          onClick={() => { handleGradeChange(GradeConfigs.A_GRADE.key) }}>
          A-grade
        </TrinityButton>
      }
      <TrinityButton className={`grid-button ${bGradable ? 'background-orange' : 'background-grey'}`}
        active={activeGrade === GradeConfigs.B_GRADE.key}
        size={'massive'}
        disabled={!bGradable}
        disabledMessage={`B-grade ${disabledMessage}`}
        onClick={() => { handleGradeChange(GradeConfigs.B_GRADE.key) }}>
        B-grade
      </TrinityButton>
      <TrinityButton className={`grid-button ${cGradable ? 'background-red' : 'background-grey'}`}
        active={activeGrade === GradeConfigs.C_GRADE.key}
        disabled={!cGradable}
        disabledMessage={`C-grade ${disabledMessage}`}
        size={'massive'}
        onClick={() => { handleGradeChange(GradeConfigs.C_GRADE.key) }}>
        C-grade
      </TrinityButton>
    </div>
  </>
}
