import { Button, Text } from '@nike/eds'
import { useGetWeightsAndMeasuresMissingQuery } from 'api/material'
import { UnitDetailsPanel } from 'components/left-detail-panel'
import { MainPanel } from 'components/main-panel'
import { Reprint } from 'components/reprint'
import { WarningBanner } from 'components/warning-banner'
import type { LabelType, Unit } from 'types'
import { type ApiHandlerContext } from 'utils/useApiHandler'

interface ReworkProps {
  unitData: Unit
  canPrintRfid: boolean
  handleReturn: () => void
  handleContinue: () => void
  printLabelContext: ApiHandlerContext<LabelType>
  weightsAndMeasuresRequired: boolean
}

export const Rework = ({ unitData, canPrintRfid, handleReturn, handleContinue, printLabelContext, weightsAndMeasuresRequired }: ReworkProps) => {
  const { data: material } = useGetWeightsAndMeasuresMissingQuery(unitData.upc, { skip: !weightsAndMeasuresRequired })

  const leftPanelContent = <UnitDetailsPanel unit={unitData} showImage={true} />
  const rightPanelContent = <Reprint unit={unitData} printLabelContext={printLabelContext} canPrintRfid={canPrintRfid} />
  const mainPanelContent =
    <div className='flex flex-col flex-grow justify-between'>
      <Text font={'title-1'} as={'h1'} className={'my-6'}>Rework...</Text>
        {material && !material.weightsAndMeasuresKnown && (
            <WarningBanner>
                Item dimensions are missing for this unit.
                <br/>
                <span className={'font-bold'}>Please bring it to the cubiscan station after rework is done.</span>
            </WarningBanner>)
        }
      <div className="flex justify-between w-100 mt-20">
      <Button className='text-4xl' onClick={handleReturn} variant='secondary'>
          Return
      </Button>
      <Button
        className='text-4xl'
        onClick={handleContinue}
      >
        Continue
      </Button>
      </div>
    </div>

  return <MainPanel
    leftPanelContent={leftPanelContent}
    mainPanelContent={mainPanelContent}
    rightPanelContent={rightPanelContent}
  />
}
