import { Card } from '@nike/eds'
import React from 'react'

import './WarningBanner.scss'

interface WarningBannerProps {
  children: React.ReactNode
}

export const WarningBanner = ({ children }: WarningBannerProps) => (
  <div className="warning-banner">
    <Card className='warning-banner-card'>
      <p className='text-4xl'>
        {children}
      </p>
    </Card>
  </div>
)
