import { TrinityButton } from 'components/trinity-button'
import { useEffect, useState } from 'react'
import { type Unit, type LabelType, LabelType as LabelTypeEnum } from 'types'
import { type ApiHandlerContext } from 'utils/useApiHandler'

interface ReprintProps {
  unit: Unit
  canPrintRfid: boolean
  printLabelContext: ApiHandlerContext<LabelType>
}

interface GradeButton {
  labelName: string
  labelToPrint: LabelType
}

const initialLoadingState = { isLoading: false, labelName: '' }

export const Reprint = ({ unit, canPrintRfid, printLabelContext }: ReprintProps) => {
  const printButtons: GradeButton[] = canPrintRfid ? [{ labelName: 'RFID', labelToPrint: LabelTypeEnum.UpcRFID }] : []
  const [loadingState, setLoadingState] = useState(initialLoadingState)

  const isApparelOrEquipment = () => {
    return unit.division === 'APPAREL' || unit.division === 'EQUIPMENT'
  }

  const isCGrade = () => {
    return unit.grade === 'C_GRADE'
  }

  isCGrade()
    ? printButtons.push({ labelName: 'C‑grade', labelToPrint: LabelTypeEnum.CGrade })
    : isApparelOrEquipment()
      ? printButtons.push({ labelName: 'UPC', labelToPrint: LabelTypeEnum.UPC })
      : printButtons.push({ labelName: 'Shoebox', labelToPrint: LabelTypeEnum.Shoebox })

  const handleOnClick = (type: LabelType) => {
    setLoadingState({ isLoading: true, labelName: type })
    printLabelContext.handleApiCall(type)
  }

  useEffect(() => {
    if (!printLabelContext.isLoading) {
      setLoadingState(initialLoadingState)
    }
  }, [printLabelContext.isLoading])

  return (
    <div className="flex flex-grow flex-col justify-center ml-6 gap-4">
      {printButtons.map((printButton) => (
        <TrinityButton
          key={printButton.labelName}
          loading={loadingState.labelName === printButton.labelToPrint && loadingState.isLoading}
          disabled={printLabelContext.isLoading}
          disabledMessage={'Printing in progress'}
          size={'massive'}
          onClick={() => { handleOnClick(printButton.labelToPrint) }}>
        <div className={'items-center flex flex-col'}>
          <img height={'80'} width={'80'} alt={'Printer icon'}
              src={process.env.PUBLIC_URL! + '/images/printer-icon.svg'}
              className={'mb-4'}/>
          Print {printButton.labelName} label
        </div>
      </TrinityButton>
      ))}
    </div>
  )
}
