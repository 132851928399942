import { useState } from 'react'

import { AlphaNumeric, NumPad } from '.'

interface KeyboardProps {
  text: string
  setText: (value: string) => void
  confirm: () => void
  className?: string
  whichKeyboard: string
}

export const VirtualKeyboard = ({ text, setText, className, confirm, whichKeyboard }: KeyboardProps) => {
  const keyboardComponentVisible: boolean = process.env.REACT_APP_KEYBOARD_COMPONENT_VISIBLE === 'true'

  const [showOnScreenKeyboard, setShowOnScreenKeyboard] = useState(false)

  if (!keyboardComponentVisible) {
    return null
  }
  if (!showOnScreenKeyboard) {
    const iconPath = process.env.PUBLIC_URL! + `/images/${whichKeyboard === 'alphanumeric' ? 'keyboard' : 'numpad'}-icon.svg`
    return (
      <div style={{ height: '400px' }} className={`flex justify-center ${className ?? ''}`} onClick={() => { setShowOnScreenKeyboard(true) }}>
        <img height={'80'} width={'80'} alt={'Keypad icon'}
           src={iconPath}
           className={'keypad-icon mb-24'} />
      </div>
    )
  }

  return whichKeyboard === 'alphanumeric'
    ? (
        <AlphaNumeric text={text} setText={setText} confirm={confirm} className={className} />
      )
    : (
        <NumPad text={text} setText={setText} confirm={confirm} className={className} />
      )
}
