import { TextField } from '@nike/eds'
import { TrinityButton } from 'components/trinity-button'
import React, { useEffect, useRef, useState } from 'react'
import { type CountryOfOrigin } from 'types'
import { isEmea } from 'utils/dcContext'

import { CountrySelect } from './country-select'

interface ManualEntryProps {
  styleColor: string
  size: string
  onStyleColorChange: (value: string) => void
  onSizeChange: (value: string) => void
  styleColorRef: React.RefObject<HTMLInputElement>
  confirm: (country: CountryOfOrigin | undefined) => void
  isLoading: boolean
}
export const ManualEntry = ({
  styleColor,
  size,
  onStyleColorChange,
  onSizeChange,
  styleColorRef,
  confirm,
  isLoading
}: ManualEntryProps) => {
  const sizeRef = useRef<HTMLInputElement>(null)
  const [country, setCountry] = useState<CountryOfOrigin | undefined>(undefined)
  const [cooIsOpen, setCooIsOpen] = useState(false)
  const [filledStyleBefore, setFilledStyleBefore] = useState(false)
  const [filledSizeBefore, setFilledSizeBefore] = useState(false)
  const [styleError, setStyleError] = useState('')
  const [sizeError, setSizeError] = useState('')

  // React is asynchronous in nature, small timeout trick to ensure rerender happens after state update
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      styleColorRef.current?.focus()
    }, 0)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    if (!filledStyleBefore && styleColor.length === 0) {
      return
    }
    setFilledStyleBefore(true)
    if (styleColor.length === 0) {
      setStyleError('Style-color is required')
    } else {
      setStyleError('')
    }
  }, [styleColor])
  useEffect(() => {
    if (!filledSizeBefore && size.length === 0) {
      return
    }
    setFilledSizeBefore(true)
    if (size.length === 0) {
      setSizeError('Size is required')
    } else {
      setSizeError('')
    }
  }, [size])

  const updateStyleColor = (value: string) => {
    const DASH_POSITION = 6
    const cleanValue = value.replace(/[^a-zA-Z0-9-]/g, '').toUpperCase()
    const firstPart = cleanValue.slice(0, DASH_POSITION).replace(/-/g, '')
    if (cleanValue.length <= DASH_POSITION) {
      onStyleColorChange(firstPart)
      return
    }
    let secondPart = cleanValue[DASH_POSITION] === '-'
      ? cleanValue.slice(DASH_POSITION + 1)
      : cleanValue.slice(DASH_POSITION)
    secondPart = secondPart.replace(/-/g, '').slice(0, 3)
    const formattedValue = `${firstPart}-${secondPart}`
    onStyleColorChange(formattedValue)
    if (firstPart.length === 6 && secondPart.length === 3) {
      sizeRef.current?.focus()
    }
  }

  const sizeTabToCoOHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab' && !e.shiftKey) {
      setCooIsOpen(true)
    }
  }

  const submitHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const validCountry: boolean = isEmea ? country !== undefined : true
    if (styleColor.length === 10 && size && validCountry) {
      if (e.key === 'Enter') {
        confirm(country)
      }
    }
  }

  return (
      <div className="flex flex-col bottom-0" onKeyDown={submitHandler}>
          <h1 className="text-6xl font-medium mb-4 mx-auto">Manual SKU</h1>
          <hr />
          <div className="flex gap-12">
              <div className={isEmea ? 'w-1/2' : 'w-full'}>
                  <TextField
                      id="style-color-textfield"
                      label=""
                      placeholder="AB1234-567"
                      value={styleColor}
                      onChange={e => {
                        updateStyleColor(e.currentTarget.value)
                      }}
                      ref={styleColorRef}
                      hasErrors={styleError !== ''}
                      errorMessage={styleError}
                      className={''}
                      maxChars={10}
                      onBlur={() => { setStyleError(styleColor.length < 10 ? 'Style-color needs 10 characters' : '') }}
                      onFocus={() => { setStyleError('') }}
                  />
                  <p style={{ marginTop: styleError ? '0px' : '-22px' }} className={'text-s text-gray-500'}>Style-color</p>
                  <TextField
                      id="size"
                      label=""
                      placeholder="M, 42, 5.5Y, MISC, 1SIZE"
                      value={size}
                      onChange={e => {
                        onSizeChange(e.currentTarget.value.toUpperCase())
                      }}
                      onKeyDown={sizeTabToCoOHandler}
                      hasErrors={sizeError !== ''}
                      errorMessage={sizeError}
                      ref={sizeRef}
                  />
                  <p className={'text-s text-gray-500'}>Size</p>
              </div>

              {isEmea && (
                  <div className="w-1/2 mt-10">
                      <CountrySelect
                          isOpen={cooIsOpen}
                          setIsOpen={setCooIsOpen}
                          selected={country}
                          onChange={setCountry}
                          confirm={(country: CountryOfOrigin) => {
                            confirm(country)
                          }}
                      />
                      <p className={'text-s text-gray-500'}>Country of Origin</p>
                  </div>
              )}
          </div>
          <TrinityButton
              className={'mt-8 w-full'}
              onClick={() => { confirm(country) }}
              loading={isLoading}
              disabled={isLoading}
              disabledMessage={'Loading...'}
          >
              <div className={'flex'}>
                  <img src={process.env.PUBLIC_URL! + '/images/enter-key-icon.png'} className={'h-6 pr-2'} alt={'Enter keyboard button image'}/>
                  Confirm
              </div>
          </TrinityButton>
      </div>
  )
}
