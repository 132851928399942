import { Icon, TextField } from '@nike/eds'
import { dispatch } from 'api/redux'
import { VirtualKeyboard } from 'components/keyboard'
import { showSnackbar } from 'components/snackbar'
import { useEffect, useState } from 'react'
import { getRlpnMaxLength, replaceGoodsHolderText } from 'utils/goodsholderContext'
import { type ApiHandlerContext } from 'utils/useApiHandler'

const RLPN_MAX_LENGTH = getRlpnMaxLength()
const RASN_MAX_LENGTH = 10

interface ScanRlpnRasnProps {
  openRlpnContext: ApiHandlerContext<string>
  createRasnContext: ApiHandlerContext<string>
}

export const ScanRlpnRasn = ({ openRlpnContext, createRasnContext }: ScanRlpnRasnProps) => {
  const [error, setError] = useState('')
  const [barcode, setBarcode] = useState('')

  useEffect(() => {
    if (barcode.length > RLPN_MAX_LENGTH) {
      setError('Barcode is too long')
    } else {
      setError('')
    }
  }, [barcode])

  const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleConfirm()
    }
  }

  const handleConfirm = () => {
    if (error === '' && barcode.length > 0) {
      if (barcode.length !== RLPN_MAX_LENGTH && barcode.length !== RASN_MAX_LENGTH) {
        setError('Invalid barcode')
        return
      }
      const context = barcode.length === RLPN_MAX_LENGTH ? openRlpnContext : createRasnContext
      if (!context.isLoading) {
        context.handleApiCall(barcode)
          .then()
          .catch(err => {
            switch (err.status) {
              case 404:
              case 423:
                dispatch(showSnackbar(replaceGoodsHolderText(err.data.message), 'error'))
                break
              default: // this case should never be reached, if so it's a bug so throw
                throw err
            }
          })
      }
      setBarcode('')
    }
  }

  return (
    <>
      <div style={{ width: '1024px' }} className={'m-auto scanTitle'}>
        <TextField
          id='rasnNumber'
          label={replaceGoodsHolderText('Please scan the rLPN/RASN barcode:')}
          placeholder={replaceGoodsHolderText('LPN barcode')}
          value={barcode}
          onChange={(e) => { setBarcode(e.currentTarget.value) }}
          autoFocus
          maxChars={RLPN_MAX_LENGTH}
          hasErrors={error !== ''}
          errorMessage={error}
          onKeyUp={keyUpHandler}
          onBlur={(e) => { e.target.focus() }}
          beforeSlot={<Icon name='Scan' />}
        />
        <div className={'absolute w-full max-w-5xl'}>
          <VirtualKeyboard text={barcode} setText={setBarcode} confirm={handleConfirm} whichKeyboard={process.env.REACT_APP_KEYBOARD!} />
        </div>
      </div>
    </>
  )
}
