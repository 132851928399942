import { Button, Icon } from '@nike/eds'

import './NumPad.css'
import 'react-simple-keyboard/build/css/index.css'

interface NumPadProps {
  text: string
  setText: (value: string) => void
  confirm: () => void
  className?: string
}

const numbersOneToNine = [...Array(9).keys()].map(i => i + 1)

export const NumPad = ({ text, setText, className, confirm }: NumPadProps) => {
  const numberButtons = numbersOneToNine.map((i) => (
    <Button key={i} onClick={() => { setText(text + i.toString()) }} variant='secondary'>{i}</Button>
  ))

  return (
      <div className={`animated fadeInBottom mt-5 numpad-buttons grid grid-cols-3 grid-rows-4 gap-2 ${className ?? ''}`}>
        {numberButtons}
        <Button onClick={() => { setText(text.slice(0, -1)) }} variant='secondary'><Icon name="ArrowLeft" size='l'/></Button>
        <Button onClick={() => { setText(text + '0') }} variant='secondary'>0</Button>
        <Button onClick={confirm} variant='secondary'><Icon name="Check" size='l'/></Button>
      </div>
  )
}
