import { Divider } from '@nike/eds'
import { type ReactElement } from 'react'

interface MainPanelProps {
  leftPanelContent: ReactElement
  mainPanelContent: ReactElement
  rightPanelContent?: ReactElement
}

export const MainPanel = ({ leftPanelContent, mainPanelContent, rightPanelContent }: MainPanelProps) => {
  return (
    <div className="unithandling grid grid-cols-12 w-full">
      <div id='leftPanel' className='col-span-4 flex'>
        {leftPanelContent}
        <Divider vertical={true} className='bg-black p-px' />
      </div>
      <div id='mainPanel' className={`relative flex justify-around px-6 ${rightPanelContent ? 'col-span-5' : 'col-span-8'}`}>
        {mainPanelContent}
      </div>
      {rightPanelContent && (
        <div id='rightPanel' className="col-span-3 flex justify-around">
          <Divider vertical={true} className='bg-black p-px' />
          {rightPanelContent}
        </div>
      )}
    </div>
  )
}
