import { type GradingInstruction } from 'types/GradingInstruction'

import { mainSplitApi } from './redux/mainSplitApi'

export const instructionApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchInstruction: builder.query<GradingInstruction, string>({
      query: (productCode) => {
        return {
          url: `/instructions/grading?productCode=${productCode}`,
          method: 'GET'
        }
      }
    })
  }),
  overrideExisting: false
})

export const { useLazyFetchInstructionQuery } = instructionApi
