import { Button, Text } from '@nike/eds'
import { UnitDetailsPanel } from 'components/left-detail-panel'
import { MainPanel } from 'components/main-panel'
import { Reprint } from 'components/reprint'
import { type LabelType, type Unit } from 'types'
import { type ApiHandlerContext } from 'utils/useApiHandler'

interface CgradeCompletionProps {
  unitData: Unit
  handleReturn: () => void
  updateUnitCgradeCompleteContext: ApiHandlerContext<Unit>
  printLabelContext: ApiHandlerContext<LabelType>
}

export const CgradeCompletion = ({ unitData, handleReturn, updateUnitCgradeCompleteContext, printLabelContext }: CgradeCompletionProps) => {
  const completeUnit = () => {
    updateUnitCgradeCompleteContext.handleApiCall({
      ...unitData,
      status: 'HANDLED'
    }).then()
  }

  const mainPanelContent =
    <div className='flex flex-col flex-grow justify-between'>
      <Text font={'title-1'} as={'h1'}>Please check if the C-grade label was correctly printed.</Text>
      <div className="flex justify-between w-100 mt-20">
        <Button className='text-2xl' onClick={handleReturn} variant='secondary'>
          Return
        </Button>
        <Button className='text-2xl' onClick={completeUnit}>
          {'Complete Unit' + (unitData.quantity > 1 ? 's' : '')}
        </Button>
      </div>
    </div>

  return <MainPanel
    leftPanelContent={<UnitDetailsPanel unit={unitData} showImage={true} />}
    mainPanelContent={mainPanelContent}
    rightPanelContent={<Reprint unit={unitData} printLabelContext={printLabelContext} canPrintRfid={false} />}
  />
}
