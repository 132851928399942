import { Text } from '@nike/eds'
import { TrinityButton } from 'components/trinity-button'
import { useMemo } from 'react'
import { getGradeReasons } from 'utils/gradeReasons'

interface GradeButtonsProps {
  gradeReason: { code: string, reason: string }
  setGradeReason: (gradeReason: { code: string, reason: string }) => void
  grade: string
}

export const GradeButtons = ({ gradeReason, setGradeReason, grade }: GradeButtonsProps) => {
  const gradeButtons = useMemo(() => {
    const reasons = getGradeReasons(grade)
    return Object.keys(reasons).map((key) => {
      const isActive = gradeReason.code === key
      return (
        <TrinityButton key={key} active={isActive} onClick={() => {
          setGradeReason({ code: reasons[key], reason: key })
        }}>
            {key}
        </TrinityButton>
      )
    })
  }, [gradeReason.reason, setGradeReason])

  return <>
    <Text font={'display-2'} as={'h1'} className={'mb-6'}>
      {grade} reason
    </Text>
    <div className="grade-button-group-container">
      {gradeButtons}
    </div>
  </>
}
