import KeyboardReact from 'react-simple-keyboard'

import './AlphaNumeric.css'
import 'react-simple-keyboard/build/css/index.css'

interface AlphaNumericProps {
  text: string
  setText: (value: string) => void
  confirm: () => void
  className?: string
}

const backspaceIcon = '⌫'

export const AlphaNumeric = ({ text, setText, confirm, className }: AlphaNumericProps) => {
  const buttonTheme = [
    {
      class: 'backspaceButton',
      buttons: `${backspaceIcon}`
    },
    {
      class: 'enterButton',
      buttons: '{enter}'
    }
  ]

  const layout = {
    default: [
      '1 2 3 4 5 6 7 8 9 0',
      'q w e r t y u i o p',
      `a s d f g h j k l ${backspaceIcon}`,
      'z x c v b n m {enter}'
    ]
  }

  const onClick = (buttonText: string) => {
    switch (buttonText) {
      case backspaceIcon:
        setText(text.slice(0, -1))
        break
      case '{enter}':
        confirm()
        break
      default:
        setText(text + buttonText.toUpperCase())
    }
  }

  return (
    <div className={`animated fadeInBottom mt-5 ${className ?? ''}`}>
      <KeyboardReact
        theme='hg-theme-default hg-layout-default custom-keyboard'
        buttonTheme={buttonTheme}
        layout={layout}
        onKeyPress={onClick}
      />
    </div>
  )
}
