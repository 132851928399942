import { Button, Icon, TextField } from '@nike/eds'
import { VirtualKeyboard } from 'components/keyboard'
import { UnitDetailsPanel } from 'components/left-detail-panel'
import { MainPanel } from 'components/main-panel'
import { useEffect, useState } from 'react'
import { type Unit } from 'types'
import { type ApiHandlerContext } from 'utils/useApiHandler'

interface ScanToteProps {
  unitData: Unit
  handleReturn: () => void
  updateUnitToteScanContext: ApiHandlerContext<Unit>
}

const TOTE_BARCODE_LENGTHS = [14, 20]

export const ScanTote = ({ unitData, handleReturn, updateUnitToteScanContext }: ScanToteProps) => {
  const [hasError, setHasError] = useState<boolean>(false)
  const [toteBarcode, setToteBarcode] = useState('')

  useEffect(() => {
    // > 0 to avoid setting error on initial render
    setHasError(toteBarcode.length > 0 && !TOTE_BARCODE_LENGTHS.includes(toteBarcode.length))
  }, [toteBarcode])

  const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleConfirm()
    }
  }

  const handleConfirm = () => {
    if (!hasError && toteBarcode.length > 0 && !updateUnitToteScanContext.isLoading) {
      updateUnitToteScanContext.handleApiCall({
        ...unitData,
        toteBarcode,
        status: 'HANDLED'
      }).then(() => { setToteBarcode('') })
    }
  }

  const leftPanelContent = <UnitDetailsPanel unit={unitData} showImage={false} />

  const mainPanelContent = <div style={{ display: '-webkit-box' }} className="flex flex-col w-full">
    <div style={{ width: '900px' }} className={'flex-grow m-auto scanTitle'}>
      <TextField
        id="toteBarcode"
        label="Please scan the putaway tote:"
        placeholder="Tote barcode"
        value={toteBarcode}
        onChange={(e) => {
          setToteBarcode(e.currentTarget.value)
        }}
        autoFocus
        maxChars={20}
        hasErrors={hasError}
        errorMessage={'Invalid putaway tote barcode'}
        onKeyUp={keyUpHandler}
        onBlur={(e) => { e.target.focus() }}
        beforeSlot={<Icon name="Scan" />}
      />
      <div className='absolute w-full max-w-4xl'>
        <VirtualKeyboard text={toteBarcode} setText={setToteBarcode} confirm={handleConfirm} whichKeyboard='numpad' />
      </div>
    </div>
    <Button onClick={handleReturn} className='absolute text-2xl bottom-0' variant='secondary'>
      Return
    </Button>
  </div>

  return <MainPanel leftPanelContent={leftPanelContent} mainPanelContent={mainPanelContent} />
}
